import React, { useState } from 'react';
import DefaultModal from 'components/modal/Default';
import CustomerModel from 'gen/CustomerModel';
import Input from 'components/inputs/Input';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { popModal, pushModal } from 'store/modal/actions';
import { useDispatchBind } from 'hooks';
import ErrorModal from 'components/modal/Error';
import Api from 'api';
import { copyJSON } from 'utils';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  selectBasicData,
  makeSelectYardCaseTypesArray,
  makeSelectYardCaseSubTypesArray,
} from 'store/basicData/selectors';
import BasicDataModel from 'gen/BasicDataModel';
import { updateBasicData } from 'store/basicData/actions';
import YardCaseType from 'gen/YardCaseType';
import CheckBox from 'components/inputs/CheckBox';
import Colors from 'constants/Colors';
import Sizes from 'constants/Sizes';
import CustomerEnabledTypeModel from 'gen/CustomerEnabledTypeModel';
import EditableList from 'components/Table/EditableList';
import YardCaseSubType from 'gen/YardCaseSubType';
import CustomerEnabledSubTypeModel from 'gen/CustomerEnabledSubTypeModel';
import Toggle from 'components/inputs/Toggle';
import { InputRowsContainer, InputRow, InputWrap } from './components';

const MahModal = styled(DefaultModal)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  padding-bottom: ${Sizes.padding.medium}px;
  width: 80vw;
  height: 80vh;
`;

const ListWrap = styled.div`
  flex: 1;
  border: 1px solid ${Colors.background.mainDark};
  border-top: none;
  margin-left: -1px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

interface Props {
  title: string;
  customer: CustomerModel;
}

const EditCustomerModal: React.FC<Props> = ({ customer, title }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [customerEdit, setCustomerEdit] = useState(copyJSON(customer));

  const basicData = useSelector(selectBasicData);

  const yardCaseTypes = useSelector(makeSelectYardCaseTypesArray);
  const yardCaseSubTypes = useSelector(makeSelectYardCaseSubTypesArray);

  const dispatch = useDispatch();
  const onPushModal = useDispatchBind(pushModal, dispatch);
  const onPopModal = useDispatchBind(popModal, dispatch);
  const onUpdateBasicData = useDispatchBind(updateBasicData, dispatch);

  const handleSaveClick = async () => {
    try {
      setIsSaving(true);
      const updatedCustomer = await Api.saveCustomer(customerEdit);
      const newBasicData = copyJSON<BasicDataModel>(basicData!);

      // insert new user into basicData
      const index = newBasicData.customers.findIndex(
        (customer) => customer.id === updatedCustomer.id
      );

      if (index < 0) {
        newBasicData.customers.push(updatedCustomer);
      } else {
        newBasicData.customers[index] = updatedCustomer;
      }
      onUpdateBasicData(newBasicData);
    } catch (err) {
      onPushModal(
        <ErrorModal exception={err}>
          Ett fel inträffade när kunden skulle läggas till. Prova att ladda om
          sidan innan du försöker lägga till kunden igen.
        </ErrorModal>
      );
    }

    onPopModal();
    setIsSaving(false);
  };

  const handleCancelClick = () => {
    onPopModal();
  };

  const handleAddTypeRule = (yardCaseType: YardCaseType) => {
    const newCustomerEdit = copyJSON(customerEdit);
    const newRule = copyJSON<CustomerEnabledTypeModel>(
      basicData!.templates.customerEnabledTypeModel
    );
    newRule.yardCaseType = yardCaseType;

    newCustomerEdit.enabledTypes.push(newRule);
    setCustomerEdit(newCustomerEdit);
  };

  const handleDeleteTypeRule = (rule: CustomerEnabledTypeModel) => {
    const newCustomerEdit = copyJSON(customerEdit);
    const unsavedId = basicData!.templates.customerEnabledTypeModel.id;

    newCustomerEdit.enabledTypes = newCustomerEdit.enabledTypes
      .map((type) =>
        type.yardCaseType === rule.yardCaseType
          ? { ...type, isRemoved: true }
          : type
      )
      .filter((type) => !(type.id === unsavedId && type.isRemoved));

    setCustomerEdit(newCustomerEdit);
  };

  const handleAddSubTypeRule = (yardCaseSubType: YardCaseSubType) => {
    const newCustomerEdit = copyJSON(customerEdit);
    const newRule = copyJSON<CustomerEnabledSubTypeModel>(
      basicData!.templates.customerEnabledSubTypeModel
    );
    newRule.yardCaseSubType = yardCaseSubType;

    newCustomerEdit.enabledSubTypes.push(newRule);
    setCustomerEdit(newCustomerEdit);
  };

  const handleDeleteSubTypeRule = (rule: CustomerEnabledSubTypeModel) => {
    const newCustomerEdit = copyJSON(customerEdit);
    const unsavedId = basicData!.templates.customerEnabledSubTypeModel.id;

    newCustomerEdit.enabledSubTypes = newCustomerEdit.enabledSubTypes
      .map((type) =>
        type.yardCaseSubType === rule.yardCaseSubType
          ? { ...type, isRemoved: true }
          : type
      )
      .filter((type) => !(type.id === unsavedId && type.isRemoved));

    setCustomerEdit(newCustomerEdit);
  };

  if (!basicData) {
    return null;
  }

  if (isSaving) {
    return (
      <MahModal buttons={[]}>
        <LoadingSpinner>Sparar kund</LoadingSpinner>
      </MahModal>
    );
  }

  return (
    <MahModal
      title={title}
      buttons={[
        { label: 'Spara', onClick: handleSaveClick },
        { label: 'Avbryt', onClick: handleCancelClick },
      ]}
    >
      <InputRowsContainer>
        <InputRow>
          <InputWrap>
            Namn
            <Input
              type="text"
              value={customerEdit.name}
              onChange={(eve) =>
                setCustomerEdit({ ...customerEdit, name: eve.target.value })
              }
            />
          </InputWrap>
          <InputWrap>
            Ta bort som alternativ i registrerings-formuläret
            <Toggle
              value={customerEdit.isRemoved}
              onToggle={(value) =>
                setCustomerEdit({ ...customerEdit, isRemoved: value })
              }
            />
          </InputWrap>
        </InputRow>
      </InputRowsContainer>

      <Horizontal>
        <ListWrap>
          <EditableList
            borderColor={Colors.background.mainDark}
            title="Aktiverade typer"
            addButtonText="Lägg till typ"
            addableItems={
              yardCaseTypes
                ? yardCaseTypes.filter(
                    (yardCaseType) =>
                      !customerEdit.enabledTypes.find(
                        (et) =>
                          et.yardCaseType === yardCaseType.value &&
                          !et.isRemoved
                      )
                  )
                : []
            }
            onAddItem={handleAddTypeRule}
            onRemoveItem={handleDeleteTypeRule}
            columnSettings={[
              {
                header: 'Typ',
                attribute: 'yardCaseType',
                formatter: (v) =>
                  basicData.yardCaseTypes[v as YardCaseType] || '',
              },
              {
                header: 'Tvinga extern referens',
                cellRenderer: ({ row }) => (
                  <CheckBox
                    value={row.requireReference}
                    onToggle={(value) => {
                      const newCustomerEdit = copyJSON(customerEdit);
                      newCustomerEdit.enabledTypes.find(
                        (type) =>
                          type.yardCaseType === row.yardCaseType &&
                          !type.isRemoved
                      )!.requireReference = value;

                      setCustomerEdit(newCustomerEdit);
                    }}
                  />
                ),
              },
            ]}
            rows={customerEdit.enabledTypes.filter((type) => !type.isRemoved)}
          />
        </ListWrap>

        <ListWrap>
          <EditableList
            borderColor={Colors.background.mainDark}
            title="Aktiverade undertyper"
            addButtonText="Lägg till undertyp"
            addableItems={
              yardCaseSubTypes
                ? yardCaseSubTypes.filter(
                    (subType) =>
                      !customerEdit.enabledSubTypes.find(
                        (et) =>
                          et.yardCaseSubType === subType.value && !et.isRemoved
                      )
                  )
                : []
            }
            onAddItem={handleAddSubTypeRule}
            onRemoveItem={handleDeleteSubTypeRule}
            columnSettings={[
              {
                header: 'Typ',
                attribute: 'yardCaseSubType',
                formatter: (v) =>
                  basicData.yardCaseSubTypes[v as YardCaseSubType] || '',
              },
            ]}
            rows={customerEdit.enabledSubTypes.filter(
              (type) => !type.isRemoved
            )}
          />
        </ListWrap>
      </Horizontal>
    </MahModal>
  );
};

export default EditCustomerModal;
